import React, { useEffect, useMemo } from 'react'
import { Navbar, NavDropdown, Nav, Image, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import dataslayerLogo from '../assets/logos/dataslayer-logo.svg'
// import userImg from '../assets/logos/user-img.jpg'
import usFlag from '../assets/logos/us-flag.svg'
import esFlag from '../assets/logos/es-flag.png'
import frFlag from '../assets/logos/fr-flag.svg'
import deFlag from '../assets/logos/de-flag.svg'
import itFlag from '../assets/logos/it-flag.svg'
import ptFlag from '../assets/logos/pt-flag.svg'
import { useAuth } from '../providers/AuthProvider'
import { FaSignOutAlt, FaRegUserCircle } from 'react-icons/fa'
import { config } from '../Constants'
import { useLocation } from 'react-router-dom'

const DATASLAYER_URL = config.DATASLAYER_URL

const Header = ({ header = 'MORPHEUS', ...props }) => {
  const { i18n, t } = useTranslation()
  const { user, signout, showStartTrial } = useAuth()
  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    const getQueryParam = (param) => {
      const params = new URLSearchParams(location.search)
      return params.get(param)
    }
    const languageParam = 'language'
    const queryLanguage = getQueryParam(languageParam)

    if (queryLanguage) {
      i18n.changeLanguage(queryLanguage)
    }
  }, [i18n, location])

  const diff = useMemo(() => {
    try {
      const date = new Date(user?.plan_data?.expiration_date)
      const diffTime = Math.abs(date.getTime() - Date.now())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return `${diffDays} ${diffDays === 1 ? t('day') : t('days')}`
    } catch (e) {
      return ''
    }
  }, [user, t])

  const flags = {
    en: usFlag,
    es: esFlag,
    fr: frFlag,
    de: deFlag,
    it: itFlag,
    pt: ptFlag,
  }

  return (
    <>
      {showStartTrial && (
        <Alert
          variant="info"
          className="sticky flex items-center top-0  left-[100px] py-0 h-[30px] rounded-0 !bg-[#fdbf00] !border-0 text-sm"
          style={{ minWidth: 'calc(100vw - 100px)' }}
        >
          <div className="text-center relative flex justify-center items-center w-full text-black font-bold">
            <span>
              {user?.plan === 'Free' ? (
                <>{t('Your trial has ended. To enjoy unlimited access')}</>
              ) : (
                <>
                  {t(
                    'You have {{days}} of trial left. To enjoy unlimited access',
                    {
                      days: diff,
                    },
                  )}
                </>
              )}
            </span>

            <button
              className="bg-transparent border-black border-2 py-0 rounded-[20px] ms-4 !font-strong hover:text-[#fdbf00] hover:!bg-black active:!scale-95 transition !duration-300 ease-in-out"
              onClick={() => navigate('/license')}
            >
              {t('UPGRADE NOW')}
            </button>
          </div>
        </Alert>
      )}
      <Navbar
        id="header"
        className={`py-0 ${showStartTrial ? 'top-[30px]' : 'top-0'}`}
      >
        <Navbar.Brand href={DATASLAYER_URL}>
          <img
            className="header-img object-contain"
            src={dataslayerLogo}
            alt="alt"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Text className="header-title py-0">{header}</Navbar.Text>
        <Nav className="header-options">
          <NavDropdown
            className="user-dropdown"
            menuVariant="dark"
            align="end"
            title={
              user?.picture ? (
                <Image
                  src={user?.picture}
                  roundedCircle
                  width="40"
                  height="40"
                />
              ) : (
                <FaRegUserCircle className="w-8 h-8" id="user-dropdown-icon" />
              )
            }
            id="user-dropdown"
          >
            <NavDropdown.Item href={`${DATASLAYER_URL}/my-profile`}>
              {t('Profile')}
            </NavDropdown.Item>
            <NavDropdown.Item href={`${DATASLAYER_URL}/billing-info`}>
              {t('Billing')}
            </NavDropdown.Item>
            <NavDropdown.Item href={`${DATASLAYER_URL}/my-company`}>
              {t('Company')}
            </NavDropdown.Item>
            <NavDropdown.Item href={`${DATASLAYER_URL}/my-activity`}>
              {t('Activity')}
            </NavDropdown.Item>
            <NavDropdown.Item href={`${DATASLAYER_URL}/my-issues`}>
              {t('Issues')}
            </NavDropdown.Item>
            <NavDropdown.Item href={`${DATASLAYER_URL}/webhooks`}>
              {t('Developers')}
            </NavDropdown.Item>
            <NavDropdown.Item className="logout-opt" onClick={() => signout()}>
              {t('Logout')} <FaSignOutAlt />
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link align="end">
                    <Image className="user-image" src={userImg} roundedCircle width="40" height="40" />
                  </Nav.Link> */}
          <NavDropdown
            menuVariant="dark"
            className="flex lang-dropdown"
            align="end"
            title={
              <>
                <Image
                  src={flags[i18n.language] ?? usFlag}
                  className="img-lang"
                />
              </>
            }
            id="nav-dropdown"
          >
            <NavDropdown.Item onClick={() => i18n.changeLanguage('en')}>
              <div className="inline-flex">
                <Image className="me-2" src={usFlag} width="20" height="20" />
                English
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('es')}>
              <div className="inline-flex items-center">
                <Image className="me-2" src={esFlag} width="22" />
                Español
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('fr')}>
              <div className="inline-flex items-center">
                <Image className="me-2" src={frFlag} width="22" />
                Français
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('de')}>
              <div className="inline-flex items-center">
                <Image className="me-2" src={deFlag} width="22" />
                Deutsch
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('it')}>
              <div className="inline-flex items-center">
                <Image className="me-2" src={itFlag} width="22" />
                Italiano
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('pt')}>
              <div className="inline-flex items-center">
                <Image className="me-2" src={ptFlag} width="22" />
                Português
              </div>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </>
  )
}

export default Header
