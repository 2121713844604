import { useState, useMemo, useRef, useEffect } from 'react'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FcTodoList, FcDeleteColumn } from 'react-icons/fc'
import { FaPlus, FaChartArea, FaCalendar } from 'react-icons/fa'
import { MdOutlineStackedBarChart, MdInvertColors } from 'react-icons/md'
import { RxCross1 } from 'react-icons/rx'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { ResponsiveBar, svgDefaultProps as barDefaultProps } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import ModelContentInfobox from './ModelContentInfobox'
import HelpTooltip from './HelpTooltip'
import OptimizeProgress from './OptimizeProgress'
import PlanningCard from './PlanningCard'
import CustomSelect from './CustomSelect'
import {
  getMMMOptimizedTable,
  getMMMOutcomeBudgetOptimization,
  getMMMStatistics,
  getModeltable,
  optimizeMMM,
} from '../services/model'
import { useAuth } from '../providers/AuthProvider'
import { readableNumberMMM, round } from '../utility/format'
import DownloadGraphOverlay from './mmm-graphs/DownloadGraphOverlay'
import { defaultProps as pieDefaultProps, ResponsivePie } from '@nivo/pie'
import {
  nivoProps,
  getMMMNonFeatureColumns,
  generateMMMColorMap,
  zip,
  nivoLineProps,
  mmmTheme,
  baseFormat,
  getModelStatus,
} from '../utility/model'
import BestSpend from './mmm-graphs/BestSpend'
import YearlyLayer from './mmm-graphs/YearlyLayer'
import ExportReport from './ExportReport'
import proCrown from '../assets/images/pro_crown.svg'

function numberWithCommas(x) {
  return x.toString().replaceAll('.', ',')
}

function calculateGraph(
  id,
  data,
  colorScheme,
  weeks,
  t,
  Legend,
  filters,
  setFilters,
  stackedGraphMode,
) {
  const base = data.graphData.map((d) => d.week - 1)
  let start = base[0]
  let end = base[base.length - 1]

  const legendData = Object.keys(data?.graphData?.[0] ?? {})
    .filter((k) => k !== 'id' && k !== 'week')
    .map((k) => ({
      id: k,
      color: colorScheme[k] ?? 'var(--nextbrain-main-color)',
    }))
  const csvKeyset = [
    'week',
    ...Object.keys(data?.graphData?.[0] ?? {}).filter(
      (d) => d !== 'week' && d !== 'id',
    ),
  ]

  const extraControls = (
    <Row>
      <Col className="controls-legend py-2 no-print" xs={12}>
        <Row>
          <Col
            className="px-0 d-flex align-items-center justify-content-center"
            xs={12}
          >
            <ButtonGroup aria-label="Legend controls">
              <Button
                className="py-1 original"
                variant={'secondary'}
                onClick={() => setFilters([])}
              >
                <strong>
                  <span className="small d-inline-flex align-items-center">
                    All <FcTodoList />
                  </span>
                </strong>
              </Button>
              <Button
                className="py-1 original"
                variant={'secondary'}
                onClick={() => setFilters([...data.itemKeys])}
              >
                <strong>
                  <span className="small d-inline-flex align-items-center">
                    Hide <FcDeleteColumn />
                  </span>
                </strong>
              </Button>
              <Button
                className="py-1 original"
                variant={'secondary'}
                onClick={() =>
                  setFilters((f) => {
                    return data.itemKeys.filter((k) => !f.includes(k))
                  })
                }
              >
                <strong>
                  <span className="small d-inline-flex align-items-center">
                    Invert
                    <MdInvertColors />
                  </span>
                </strong>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  if (stackedGraphMode) {
    const stackedData = data.itemKeys
      .filter((k) => !filters.includes(k))
      .map((k) => ({
        id: k,
        data: data.graphData.map((d, i) => ({
          x: i + 1,
          y: d[k],
          index: i,
        })),
        color: colorScheme[k],
      }))

    try {
      const stacked = (
        <ResponsiveLine
          {...nivoLineProps}
          {...nivoProps}
          data={stackedData}
          margin={{ top: 50, right: 350, bottom: 120, left: 110 }}
          colors={(d) => colorScheme[d.id]}
          curve="linear"
          areaBlendMode="normal"
          lineWidth={2}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          areaOpacity={0.5}
          axisTop={null}
          axisRight={null}
          useMesh={true}
          axisBottom={{
            tickSize: 15,
            tickPadding: 5,
            legend: t('Week'),
            legendPosition: 'middle',
            legendOffset: 100,
            tickRotation: -30,
          }}
          axisLeft={{
            tickSize: 5,
            translateX: -100,
            tickPadding: 5,
            tickRotation: 0,
            legend: t('Spend'),
            legendPosition: 'middle',
            legendOffset: -100,
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={152}
          enableArea={true}
          enableSlices="x"
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            'points',
            'slices',
            'mesh',
            'legends',
            (props) => (
              <YearlyLayer
                offset={70}
                start={start}
                end={end}
                ignoreLessThanYear={true}
                {...props}
              />
            ),
            () => {
              const goodIndexes = csvKeyset
                .map((k, i) => !filters.includes(csvKeyset[i]))
                .reduce((acc, cur, i) => {
                  acc[i] = cur
                  return acc
                }, {})

              const finalData = [
                csvKeyset.filter((_, i) => goodIndexes[i]),
                ...data.graphData.map((d) =>
                  csvKeyset.filter((_, i) => goodIndexes[i]).map((k) => d[k]),
                ),
              ].map((v, i) => (i ? v.map((vv) => numberWithCommas(vv)) : v))
              return (
                <div
                  className="data-holder display-none"
                  data-csv={encodeURIComponent(JSON.stringify(finalData))}
                  data-filename={`time_allocation__${id}`}
                ></div>
              )
            },
            (props) => (
              <>
                <Legend
                  data={legendData}
                  extraControls={extraControls}
                  items={data.itemKeys.map((k) => ({
                    id: k,
                    color: colorScheme[k],
                  }))}
                  filters={filters}
                  onClick={(k) =>
                    setFilters((f) => {
                      if (f.includes(k)) return f.filter((v) => v !== k)
                      return [...f, k]
                    })
                  }
                  {...props}
                />
              </>
            ),
          ]}
          theme={{
            ...mmmTheme,
            tooltip: {
              container: {
                fontSize: 11,
                color: 'black',
              },
            },
          }}
        />
      )
      return stacked
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ResponsiveBar
      {...nivoProps}
      data={data.graphData}
      keys={data.itemKeys.filter((k) => !filters.includes(k))}
      indexBy="week"
      margin={{ top: 50, right: 350, bottom: 120, left: 110 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={(d) => colorScheme[d.id]}
      defs={[]}
      fill={[]}
      axisTop={null}
      axisRight={null}
      valueFormat={(d) => `${round(d, 2)}`}
      enableLabel={false}
      axisBottom={{
        tickSize: 15,
        tickPadding: 5,
        legend: t('Week'),
        legendPosition: 'middle',
        legendOffset: 100,
        tickRotation: -30,
      }}
      axisLeft={{
        tickSize: 5,
        translateX: -100,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Spend'),
        legendPosition: 'middle',
        legendOffset: -100,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[]}
      layers={[
        ...barDefaultProps.layers,
        () => {
          const goodIndexes = csvKeyset
            .map((k, i) => !filters.includes(csvKeyset[i]))
            .reduce((acc, cur, i) => {
              acc[i] = cur
              return acc
            }, {})

          const finalData = [
            csvKeyset.filter((_, i) => goodIndexes[i]),
            ...data.graphData.map((d) =>
              csvKeyset.filter((_, i) => goodIndexes[i]).map((k) => d[k]),
            ),
          ].map((v, i) => (i ? v.map((vv) => numberWithCommas(vv)) : v))

          return (
            <div
              className="data-holder display-none"
              data-csv={encodeURIComponent(JSON.stringify(finalData))}
              data-filename={`time_allocation__${id}`}
            ></div>
          )
        },
        (props) => (
          <YearlyLayer
            offset={70}
            start={start}
            end={end}
            ignoreLessThanYear={true}
            {...props}
          />
        ),
        (props) => (
          <>
            <Legend
              data={legendData}
              extraControls={extraControls}
              items={data.itemKeys.map((k) => ({
                id: k,
                color: colorScheme[k],
              }))}
              filters={filters}
              {...props}
              onClick={(k) =>
                setFilters((f) => {
                  if (f.includes(k)) return f.filter((v) => v !== k)
                  return [...f, k]
                })
              }
            />
          </>
        ),
      ]}
    />
  )
}

function useBarLegend({
  trim = Infinity,
  top = false,
  paddingX = 20,
  fontSize = 12,
  onClick,
}) {
  const ref = useRef(
    ({
      data,
      innerWidth,
      innerHeight,
      items,
      margin,
      extraControls = null,
      onClick,
      filters,
    }) => {
      return (
        <foreignObject
          x={innerWidth + paddingX}
          y={0}
          width={margin.right - paddingX}
          height={innerHeight}
        >
          <Row
            className="ms-2 container-line-legend-interactive"
            style={{
              minWidth: `${margin.right - paddingX - 10}px`,
              maxWidth: `${margin.right - paddingX - 10}px`,
              maxHeight: `${innerHeight - margin.bottom}px`,
              overflowY: 'auto',
            }}
          >
            {extraControls && <Col xs={12}>{extraControls}</Col>}
            {items.map((s, i) => (
              <Col className={i ? '' : 'mt-2'} key={s.id} xs={12}>
                <Row
                  className={`interactive-legend-block icon-btn ${filters?.includes(s?.id) ? 'opacity-50' : ''}`}
                  onClick={() => onClick(s.id)}
                >
                  <Col
                    className="px-0 d-flex align-items-center"
                    style={{
                      position: 'relative',
                      maxWidth: '100%',
                      minWidth: '100%',
                    }}
                    xs={10}
                  >
                    <span
                      title={s.id}
                      className="w-100 detail-legend-interactive d-inline-block text-truncate"
                      style={{ fontSize: '11px' }}
                    >
                      <div
                        style={{
                          backgroundColor: `${s.color}`,
                          minWidth: '10px',
                          minHeight: '10px',
                          maxWidth: '10px',
                          maxHeight: '10px',
                          borderRadius: '50%',
                          display: 'inline-block',
                          marginRight: '2px',
                        }}
                        className="detail-legend-interative-shape"
                      ></div>
                      {s.id}
                    </span>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </foreignObject>
      )
    },
  )
  return ref.current
}

export function usePieLegend({ trim = Infinity, height, paddingX = 20 }) {
  const ref = useRef(({ dataWithArc, centerX, radius }) => {
    const space = dataWithArc.length * 20
    let top = 0
    if (space > height)
      dataWithArc = dataWithArc.slice(0, Math.floor(height / 20))
    else top = (height - space) / 2
    return (
      <g
        className="hot-stuff"
        transform={`translate(${centerX + radius + paddingX}, ${top})`}
      >
        {dataWithArc.map(({ id, color }, i) => (
          <g title={id} key={i} transform={`translate(0,${i * 20})`}>
            <circle cx={0} cy={0} r={6} fill={color} />
            <text fontSize={12} x={10} y={3} fill="white">
              {id?.length > trim + 2 ? id.slice(0, trim) + '...' : id}
            </text>
          </g>
        ))}
      </g>
    )
  })

  return ref.current
}

function BoundsConfig({ model, bounds, setBounds }) {
  const { t } = useTranslation()
  const mediaCols = useMemo(
    () =>
      model?.trainable_columns
        ?.filter(
          (c) =>
            !(
              model?.training_config?.extra_features?.includes(c) ||
              model?.training_config?.columns_to_ignore?.includes(c) ||
              model?.training_config?.target === c ||
              model?.training_config?.datetime_col === c
            ),
        )
        ?.map((v) => ({ label: v, value: v })) ?? [],
    [model],
  )

  return (
    <Row>
      {bounds.map(({ bound, ceil, floor }, i) => (
        <Col className="mt-2" xs={12} key={`${i}-${Date.now()}`}>
          <Row>
            <Col xs={12} style={{ maxWidth: 'calc(100% - 50px)' }}>
              <Row>
                <Col className="inline-flex items-end" xs={3}>
                  {t('Media')}
                </Col>
                <Col className="inline-flex items-end" xs={3}>
                  {t('Spend floor')}
                </Col>
                <Col className="inline-flex items-end" xs={3}>
                  {t('Ceil')}
                </Col>
              </Row>
              <Row>
                <Col className="inline-flex items-end" xs={3}>
                  <CustomSelect
                    className="basic-single mt-2 w-full"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder={'Select...'}
                    isClearable={false}
                    onChange={(e) => {
                      bounds[i].bound = e?.value
                      setBounds([...bounds])
                    }}
                    options={mediaCols}
                    value={{ label: bound, value: bound }}
                  />
                </Col>
                <Col className="inline-flex items-end" xs={3}>
                  <Form.Control
                    className="text-input-mmm"
                    onBlur={(e) => {
                      bounds[i].floor = e.target.value
                      setBounds([...bounds])
                    }}
                    defaultValue={floor}
                  />
                </Col>
                <Col className="inline-flex items-end relative" xs={3}>
                  <Form.Control
                    className="text-input-mmm"
                    onBlur={(e) => {
                      bounds[i].ceil = e.target.value
                      setBounds([...bounds])
                    }}
                    defaultValue={ceil}
                  />
                  <RxCross1
                    size={30}
                    className="absolute text-red-700 cursor-pointer -right-12 "
                    onClick={() =>
                      setBounds((b) => b.filter((_, j) => j !== i))
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

export default function ModelPlanning({ model }) {
  const { t } = useTranslation()
  const { token, isEssential } = useAuth()
  const requirePro = isEssential
  const [budgetMode, setBudgetMode] = useState(true)
  const targetType = budgetMode ? 'budget' : 'outcome'
  const ammountRef = useRef()
  const weeksRef = useRef()
  const calendarRef = useRef()
  const [selectedFeatureBudget, setSelectedFeatureBudget] = useState()
  const [selectedFeatureOutcome, setSelectedFeatureOutcome] = useState()
  const [startingDate, setStartingDate] = useState(() => {
    return model?.budget_allocation_params_budget?.starting_date
      ? new Date(model?.budget_allocation_params_budget?.starting_date * 1000)
      : null
  })
  const currentFeatureSelected = budgetMode
    ? selectedFeatureBudget
    : selectedFeatureOutcome
  const pieLegend = usePieLegend({ height: 290, paddingX: 50, trim: 35 })
  const [stackedGraphMode, setStackedGraphMode] = useState(true)
  const [filters, setFilters] = useState([])

  const [budgetGraph, setBudgetGraph] = useState(<></>)
  const [outcomeGraph, setOutcomeGraph] = useState(<></>)

  const { data: budgetTable } = useQuery(
    ['budget-optimize', model?.id],
    async () => {
      const res = await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: false,
        weekly: true,
        original: false,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )

  const { data: outcomeTable } = useQuery(
    ['outcome-optimize', model?.id],
    async () => {
      const res = await getMMMOptimizedTable({
        modelId: model.id,
        is_outcome_optimization: true,
        weekly: true,
        original: false,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )

  const { data: outcomeResult, isLoading: outcomeLoading } = useQuery(
    ['mmm-model-optimization-outcome', model?.id],
    async () => {
      const res = await getMMMOutcomeBudgetOptimization({
        modelId: model?.id,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )

  const invalidBudgetOptimization =
    budgetMode && outcomeResult?.outcome && outcomeResult?.outcome < 0

  const loadBounds = (item) => {
    const result = []
    Object.keys(item?.channel_to_max_spend ?? {}).forEach((k) => {
      const current = result.find((v) => v.bound === k)
      if (current) current.ceil = item.channel_to_max_spend[k]
      else result.push({ bound: k, ceil: item.channel_to_max_spend[k] })
    })

    Object.keys(item?.channel_to_min_spend ?? {}).forEach((k) => {
      const current = result.find((v) => v.bound === k)
      if (current) current.floor = item.channel_to_max_spend[k]
      else result.push({ bound: k, floor: item.channel_to_min_spend[k] })
    })
    return result
  }
  const [bounds, setBounds] = useState(() =>
    loadBounds(model?.budget_allocation_params_budget),
  )

  const [optimizeRequest, setOptimizeRequest] = useState(null)
  useEffect(() => {
    if (optimizeRequest) {
      optimizeMMM({
        modelId: model.id,
        channelToMinSpend: optimizeRequest.bounds.reduce((acc, b) => {
          if (
            (b.floor || b.floor === 0) &&
            !Number.isNaN(Number.parseFloat(b.floor))
          )
            acc[b.bound] = Number.parseFloat(b.floor)
          return acc
        }, {}),
        channelToMaxSpend: optimizeRequest.bounds.reduce((acc, b) => {
          if (
            (b.ceil || b.ceil === 0) &&
            !Number.isNaN(Number.parseFloat(b.ceil))
          )
            acc[b.bound] = Number.parseFloat(b.ceil)
          return acc
        }, {}),
        outcomeTarget: optimizeRequest.outcome,
        budgetTarget: optimizeRequest.budget,
        weeks: optimizeRequest.weeks,
        startingDate: optimizeRequest.startingDate,
        token,
      }).finally(() => {
        model.status = 'optimizing'
        queryClient.invalidateQueries(['model', model.id])
        queryClient.setQueryData(['model-progress-optimize', model.id], {
          finished: false,
          level: 'INFO',
          message: 'Optimization starting...',
          model_id: model.id,
          name: 'Marketing Mix Modeling',
          percent: 0,
        })
        setOptimizeRequest(null)
      })
    }
  }, [optimizeRequest])

  const disabledInput = optimizeRequest || model.status === 'optimizing'

  const { data: table } = useQuery(
    ['model-optimization-table', model.id],
    async () => {
      const res = await getModeltable({
        modelId: model.id,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )

  const mediaContribution = useMemo(() => {
    if (table?.columns) {
      const index = table?.columns?.indexOf('Media contribution')
      return (
        table?.data?.reduce((acc, row) => {
          if (Number.isNaN(row[index])) return acc
          return acc + row[index]
        }, 0) / 100
      )
    }
    return 1
  }, [table])
  const [colorScheme] = useState(() => {
    return generateMMMColorMap(model)
  })

  const optimizeTableBars = useMemo(() => {
    const graphData = []
    const itemKeys = []
    const pieDistribution = []
    const spendPerChannel = {}
    let weeks = 0
    try {
      const [id, total, start] = [
        'index',
        'Total Proposed spend',
        'Week_0',
      ].map((k) => budgetTable.columns.indexOf(k))
      let acum = 0
      const spend = budgetTable.data.reduce((acc, data) => {
        acc[data[id]] = data[total]
        acum += data[total]
        spendPerChannel[data[id]] = data
          .slice(start, data.length - 4 + start)
          .reduce((acc, cur) => acc + cur, 0)
        return acc
      }, {})

      Object.keys(spend).forEach((k) => (spend[k] /= acum))
      pieDistribution.push(
        ...Object.entries(spend)
          .filter(([k, v]) => v > 0.01)
          .map(([k, v]) => ({
            id: k,
            value: v * 100,
            color: colorScheme[k],
            label: k,
          })),
      )

      const validKeyRegs = /Week_[0-9]+/
      const validIndexes = budgetTable.columns.reduce((acc, key, i) => {
        if (key.match(validKeyRegs)) acc[i] = true
        return acc
      }, {})
      const data = budgetTable.data
        .map(([k, ...v]) =>
          spend[k] < 0.01
            ? null
            : {
                id: k,
                data: v.filter((_, i) => validIndexes[i + 1]),
              },
        )
        .filter((v) => v)
      const keys = data.map((d) => d.id)
      const newData = zip(data.map((d) => d.data)).map((v, i) => ({
        id: `week ${i + 1}`,
        week: i + 1,
        ...v.reduce((acc, cur, i) => {
          acc[keys[i]] = cur
          return acc
        }, {}),
      }))
      weeks = newData.length
      Object.keys(spendPerChannel).forEach((k) => {
        spendPerChannel[k] /= weeks
      })
      graphData.push(...newData)
      itemKeys.push(...keys)
    } catch (e) {}
    return { graphData, itemKeys, pieDistribution, spendPerChannel, weeks }
    // eslint-disable-next-line
  }, [budgetTable])

  const optimizeTableOutcomeBars = useMemo(() => {
    const graphData = []
    const itemKeys = []
    const pieDistribution = []
    let totalSpend = 0
    const spendPerChannel = {}
    let weeks = 0
    try {
      const [id, total, start] = [
        'index',
        'Total Proposed spend',
        'Week_0',
      ].map((k) => outcomeTable.columns.indexOf(k))
      const spend = outcomeTable.data.reduce((acc, data) => {
        acc[data[id]] = data[total]
        totalSpend += data[total]
        spendPerChannel[data[id]] = data
          .slice(start, data.length - 4 + start)
          .reduce((acc, cur) => acc + cur, 0)
        return acc
      }, {})
      Object.keys(spend).forEach((k) => (spend[k] /= totalSpend))
      pieDistribution.push(
        ...Object.entries(spend)
          .filter(([k, v]) => v > 0.01)
          .map(([k, v]) => ({
            id: k,
            value: v * 100,
            color: colorScheme[k],
            label: k,
          })),
      )

      const validKeyRegs = /Week_[0-9]+/
      const validIndexes = outcomeTable.columns.reduce((acc, key, i) => {
        if (key.match(validKeyRegs)) acc[i] = true
        return acc
      }, {})
      const data = outcomeTable.data.map(([k, ...v]) => ({
        id: k,
        data: v.filter((_, i) => validIndexes[i + 1]),
      }))
      const keys = data.map((d) => d.id)
      const newData = zip(data.map((d) => d.data)).map((v, i) => ({
        id: `week ${i + 1}`,
        week: i + 1,
        ...v.reduce((acc, cur, i) => {
          acc[keys[i]] = cur
          return acc
        }, {}),
      }))
      weeks = newData.length
      Object.keys(spendPerChannel).forEach((k) => {
        spendPerChannel[k] /= weeks
      })
      graphData.push(...newData)
      itemKeys.push(...keys)
    } catch (e) {}
    return {
      graphData,
      itemKeys,
      pieDistribution,
      totalSpend,
      spendPerChannel,
      weeks,
    }
    // eslint-disable-next-line
  }, [outcomeTable])

  const { data: statistics } = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
      })
      if (!response?.ok) toast.error(t('Failed to retrieve original forecast'))
      else return await response.json()
    },
    { staleTime: Infinity },
  )
  const testAccuracy = () => {
    if (statistics?.testAccuracy) return statistics.testAccuracy * 0.01
    return 0.95
  }

  const features =
    (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)?.graphData
      ?.length > 0 &&
    getMMMNonFeatureColumns(model).filter(
      (v) =>
        (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)
          .spendPerChannel[v] > 1,
    )
  const Legend = useBarLegend({
    trim: 21,
  })
  useEffect(() => {
    setBudgetGraph(
      calculateGraph(
        model.id,
        optimizeTableBars,
        colorScheme,
        optimizeTableBars.weeks,
        t,
        Legend,
        filters,
        setFilters,
        stackedGraphMode,
      ),
    )
    // eslint-disable-next-line
  }, [optimizeTableBars, filters, stackedGraphMode])
  useEffect(() => {
    setOutcomeGraph(
      calculateGraph(
        model.id,
        optimizeTableOutcomeBars,
        colorScheme,
        optimizeTableOutcomeBars.weeks,
        t,
        Legend,
        filters,
        setFilters,
        stackedGraphMode,
      ),
    )
    // eslint-disable-next-line
  }, [optimizeTableOutcomeBars, filters, stackedGraphMode])

  useEffect(() => {
    if (!currentFeatureSelected?.value && features?.[0]) {
      if (budgetMode) {
        setSelectedFeatureBudget({ label: features[0], value: features[0] })
      } else {
        setSelectedFeatureOutcome({ label: features[0], value: features[0] })
      }
    }
  }, [
    currentFeatureSelected,
    selectedFeatureBudget,
    selectedFeatureOutcome,
    budgetMode,
    features,
  ])

  const budgetTarget = model?.budget_allocation_params_budget?.budget_target
  const outcomeTarget = model?.budget_allocation_params_outcome?.outcome_target

  const minDate = dayjs(
    new Date(
      model?.column_statistics?.[
        model?.dataslayer_training_config?.time_column
      ]?.max,
    ),
  )

  return (
    <Row>
      <Col xs={12}>
        <ModelContentInfobox
          applyInfoboxClass={true}
          collapsable={true}
          name={'planning_infobox'}
          className="mb-2"
        >
          {t('Planning infobox')}
        </ModelContentInfobox>
      </Col>
      <Col
        className={`train-container mt-2 py-3 rounded-2xl relative duration-200`}
        xs={12}
      >
        <Row className="ps-3">
          <Col xs={12}>
            <Row className="text-xl justify-content-between">
              <Col xs="auto">
                <Row>
                  <Col
                    className={`button-budget pe-0 rounded-l-3xl py-2 outline-main ${budgetMode ? 'bg-main user-select-none pointer-events-none' : 'cursor-pointer'}`}
                    xs={'auto'}
                    onClick={() => {
                      setBudgetMode(true)
                      setBounds(
                        loadBounds(model?.budget_allocation_params_budget),
                      )
                      setStartingDate(
                        model?.budget_allocation_params_budget?.starting_date
                          ? new Date(
                              model?.budget_allocation_params_budget
                                ?.starting_date * 1000,
                            )
                          : null,
                      )
                    }}
                  >
                    <span className="mx-5">{t('Base on my budget')}</span>
                  </Col>
                  <Col
                    className={`button-outcome ps-0 rounded-r-3xl py-2 outline-main ${budgetMode ? 'cursor-pointer' : 'bg-main user-select-none pointer-events-none'}`}
                    xs={'auto'}
                    onClick={() => {
                      setBudgetMode(false)
                      setBounds(
                        loadBounds(model?.budget_allocation_params_outcome),
                      )
                      setStartingDate(
                        model?.budget_allocation_params_outcome?.starting_date
                          ? new Date(
                              model?.budget_allocation_params_outcome
                                ?.starting_date * 1000,
                            )
                          : null,
                      )
                    }}
                  >
                    <span className="mx-5">{t('Base on my outcome')}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className={`text-xl mb-3 duration-200 mt-5`}
            key={targetType}
          >
            <Row>
              <Col xs={9}>
                <Row>
                  <Col xxl={4} md={6} xs={6}>
                    <Row>
                      <Col className="mb-2" xs={12}>
                        {t(budgetMode ? 'Budget' : 'Outcome')}
                      </Col>
                      <Col xs={12}>
                        <Form.Control
                          className="text-input-mmm"
                          ref={ammountRef}
                          disabled={disabledInput}
                          defaultValue={
                            (budgetMode
                              ? model?.budget_allocation_params_budget
                                  ?.budget_target
                              : model?.budget_allocation_params_outcome
                                  ?.outcome_target) ?? ''
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={4} md={6} xs={6}>
                    <Row>
                      <Col className="mb-2" xs={12}>
                        {t('Period (Weeks)')}
                      </Col>
                      <Col xs={12}>
                        <Form.Control
                          className="text-input-mmm"
                          ref={weeksRef}
                          disabled={disabledInput}
                          defaultValue={
                            (budgetMode
                              ? model?.budget_allocation_params_budget?.weeks
                              : model?.budget_allocation_params_outcome
                                  ?.weeks) ?? ''
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={4} md={6} xs={6}>
                    <Row>
                      <Col className="mb-2" xs={12}>
                        {t('Starting week')}
                      </Col>
                      <Col className="relative" xs={12}>
                        <FaCalendar
                          size={20}
                          className="absolute top-[calc(50%-10px)] left-[25px] pointer-events-none"
                        />
                        <span className="absolute left-0 pointer-events-none">
                          <span className="opacity-0 ">
                            <DatePicker
                              ref={calendarRef}
                              value={
                                startingDate
                                  ? dayjs(new Date(Date.now()))
                                  : dayjs(startingDate)
                              }
                              onChange={(date) => {
                                setStartingDate(new Date(date))
                              }}
                              minDate={minDate}
                            />
                          </span>
                        </span>
                        <Form.Control
                          className="text-input-mmm !ps-11 cursor-pointer"
                          onClick={() => {
                            calendarRef.current
                              ?.querySelector('button')
                              ?.click()
                          }}
                          value={
                            startingDate
                              ? dayjs(startingDate).format('DD/MM/YYYY')
                              : ''
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={3}>
                <Row>
                  <Col className="mb-1 opacity-0 pointer-events-none" xs={11}>
                    placeholder
                  </Col>
                  <Col xs={11}>
                    <button
                      className={`w-full button-mmm rounded-3xl py-2 bg-main disabled:bg-slate-950 disabled:pointer-events-none mt-0 font-bold ${disabledInput || requirePro || model?.read_only ? 'opacity-50' : ''}`}
                      disabled={disabledInput || requirePro || model?.read_only}
                      onClick={() => {
                        const value = Number.parseFloat(
                          ammountRef?.current?.value,
                        )
                        const weeks = Number.parseFloat(
                          weeksRef?.current?.value,
                        )
                        if (Number.isNaN(value)) {
                          toast.error(
                            'Please enter a valid number for the ' + targetType,
                          )
                          ammountRef.current.focus()
                          return
                        }
                        if (Number.isNaN(weeks)) {
                          toast.error(
                            'Please enter a valid number for the weeks',
                          )
                          weeksRef.current.focus()
                          return
                        }
                        setOptimizeRequest({
                          [targetType]: value,
                          weeks,
                          bounds,
                          startingDate,
                        })
                      }}
                    >
                      <div className="flex  items-center justify-center">
                        {requirePro && (
                          <img src={proCrown} className="h-[20px] me-2" />
                        )}
                        {t('Calculate plan')}
                      </div>
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className={`mb-3 duration-200 mt-3`}>
            <Row>
              <Col
                className="font-extrabold text-xl inline-flex items-center"
                xs={12}
              >
                {t('Personalize channel budgets')}
                <HelpTooltip
                  message={t('Constraints help')}
                  className="ms-2 mt-1"
                />
              </Col>
              <Col xs={12}>
                <Row className="ms-3">
                  {/* <Col xs={12}>
                    <span className="font-extralight">
                      {t('Constraints label')}
                    </span>
                  </Col> */}
                  <Col xs={12}>
                    <button
                      className="button-mmm rounded-3xl py-2 bg-main disabled:bg-slate-950 disabled:pointer-events-none mt-2 font-bold"
                      onClick={() =>
                        bounds.find((b) => !b.bound) ||
                        setBounds((b) => [...b, {}])
                      }
                      disabled={disabledInput}
                    >
                      <span className="inline-flex items-center">
                        <FaPlus className="me-3" />
                        {t('Add constraint')}
                      </span>
                    </button>
                  </Col>
                  <Col
                    className={`mt-2 ${disabledInput ? 'pointer-events-none' : ''}`}
                    xs={12}
                  >
                    <BoundsConfig
                      model={model}
                      bounds={bounds}
                      setBounds={setBounds}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {model?.status === 'optimizing' && !optimizeRequest && (
        <Col xs={12}>
          <OptimizeProgress model={model} />
        </Col>
      )}
      {model?.status !== 'optimizing' &&
      !optimizeRequest &&
      ((budgetMode && budgetTable) ||
        (!budgetMode && optimizeTableOutcomeBars?.totalSpend)) ? (
        <Col xs={12}>
          <Row>
            <Col
              className={`train-container mt-4 py-3 rounded-2xl relative duration-200`}
              xs={12}
            >
              <Row className="ps-3 justify-between">
                <Col xs="auto" className={`text-xl mb-3 duration-200`}>
                  {t('Planning results')}
                </Col>
                {getModelStatus(model) == 'trained' && (
                  <Col xs="auto">
                    <ExportReport />
                  </Col>
                )}
                <Col xs={12}>
                  {budgetMode ? (
                    <Row className="justify-center inline-flex">
                      <Col className="text-xl mb-2" xs={6}>
                        {t('Outcome')}
                      </Col>
                      <Col className="text-xl mb-2" xs={6}>
                        {t('ROI')}
                      </Col>
                      <Col xs={12} style={{ maxWidth: 'calc(50% - 5px)' }}>
                        <Row>
                          <Col xl={6}>
                            <PlanningCard
                              info={true}
                              helpText={t('Media outcome help')}
                              message={t('Media outcome')}
                              value={readableNumberMMM(
                                outcomeResult?.outcome * mediaContribution,
                                2,
                                4,
                              )}
                              error={readableNumberMMM(
                                outcomeResult?.outcome *
                                  mediaContribution *
                                  (1 - testAccuracy()),
                                2,
                                4,
                              )}
                            />
                          </Col>
                          <Col xl={6}>
                            <PlanningCard
                              info={true}
                              helpText={t('Total KPI help')}
                              message={t('Total KPI')}
                              value={readableNumberMMM(
                                outcomeResult?.outcome,
                                2,
                                4,
                              )}
                              error={readableNumberMMM(
                                outcomeResult?.outcome * (1 - testAccuracy()),
                                2,
                                4,
                              )}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <div className="splitter-optimization"></div>
                      <Col xs={12} style={{ maxWidth: 'calc(50% - 5px)' }}>
                        <Row>
                          <Col xl={6}>
                            <PlanningCard
                              message={t('Media ROI')}
                              helpText={t('Media ROI help')}
                              value={readableNumberMMM(
                                (100 *
                                  (outcomeResult?.outcome * mediaContribution -
                                    budgetTarget)) /
                                  budgetTarget,
                                2,
                                4,
                              )}
                              error={readableNumberMMM(
                                (1 - testAccuracy()) /
                                  ((budgetTarget * 0.01) /
                                    (outcomeResult?.outcome *
                                      mediaContribution -
                                      budgetTarget)),
                                2,
                                4,
                              )}
                            />
                          </Col>
                          <Col xl={6}>
                            <PlanningCard
                              helpText={t('Total ROI help')}
                              message={t('Total ROI')}
                              value={readableNumberMMM(
                                (100 *
                                  (outcomeResult?.outcome - budgetTarget)) /
                                  budgetTarget,
                                2,
                                4,
                              )}
                              error={readableNumberMMM(
                                (1 - testAccuracy()) /
                                  (budgetTarget /
                                    (100 *
                                      (outcomeResult?.outcome - budgetTarget))),
                                4,
                              )}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="justify-start">
                      <Col xl={3} md={6}>
                        <PlanningCard
                          info={true}
                          value={readableNumberMMM(
                            optimizeTableOutcomeBars?.totalSpend,
                            2,
                            4,
                          )}
                          error={readableNumberMMM(
                            optimizeTableOutcomeBars?.totalSpend *
                              (1 - testAccuracy()),
                            2,
                            4,
                          )}
                          message={t('Media Spend')}
                        />
                      </Col>
                      <div className="splitter-optimization"></div>
                      <Col xl={3} md={6}>
                        <PlanningCard
                          value={readableNumberMMM(
                            (100 *
                              (outcomeTarget -
                                optimizeTableOutcomeBars?.totalSpend)) /
                              optimizeTableOutcomeBars?.totalSpend,
                            2,
                            4,
                          )}
                          error={readableNumberMMM(
                            (1 - testAccuracy()) /
                              ((optimizeTableOutcomeBars?.totalSpend * 0.01) /
                                (outcomeTarget -
                                  optimizeTableOutcomeBars?.totalSpend)),
                            2,
                            4,
                          )}
                          helpText={t('Media ROI help')}
                          message={t('Media ROI')}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
            {!model.status !== 'optimizing' &&
              !optimizeRequest &&
              (budgetMode ? optimizeTableBars : optimizeTableOutcomeBars)
                .pieDistribution.length > 0 &&
              !invalidBudgetOptimization && (
                <Col
                  className={`train-container mt-4 py-3 rounded-2xl relative duration-200`}
                  xs={12}
                >
                  <Row className="ps-3">
                    <Col xs={12} className={`text-xl mb-3 duration-200`}>
                      {t('Plan strategy')}
                    </Col>
                    <Col xs={12}>
                      <Row className="position-relative">
                        <Col
                          style={{ minHeight: '400px', maxHeight: '400px' }}
                          xs={12}
                          lg={6}
                        >
                          <DownloadGraphOverlay>
                            <ResponsivePie
                              {...nivoProps}
                              data={
                                (budgetMode
                                  ? optimizeTableBars
                                  : optimizeTableOutcomeBars
                                ).pieDistribution
                              }
                              margin={{
                                left: 0,
                                bottom: 60,
                                right: 250,
                                top: 60,
                              }}
                              innerRadius={0.5}
                              colors={(d) => d.data.color}
                              padAngle={0.7}
                              cornerRadius={0}
                              axisLeft={null}
                              axisRight={null}
                              axisTop={null}
                              axisBottom={null}
                              activeOuterRadiusOffset={8}
                              valueFormat={(n) => `${round(n, 1)}%`}
                              borderWidth={1}
                              borderColor={{
                                from: 'color',
                                modifiers: [['darker', 0.2]],
                              }}
                              enableArcLabels={false}
                              enableArcLinkLabels={false}
                              arcLinkLabelsSkipAngle={10}
                              arcLinkLabelsDiagonalLength={15}
                              arcLinkLabelsStraightLength={20}
                              defs={[]}
                              fill={[]}
                              legends={[]}
                              layers={[
                                ...pieDefaultProps.layers,
                                () => (
                                  <div
                                    className="data-holder display-none"
                                    data-csv={encodeURIComponent(
                                      JSON.stringify([
                                        ['channel', 'media mix'],
                                        ...(budgetMode
                                          ? optimizeTableBars
                                          : optimizeTableOutcomeBars
                                        ).pieDistribution.map((d) => [
                                          d.id,
                                          d.value,
                                        ]),
                                      ]),
                                    )}
                                    data-filename={`scenario_mediamix__${model.name}`}
                                  ></div>
                                ),
                                pieLegend,
                              ]}
                            />
                          </DownloadGraphOverlay>
                        </Col>
                        <Col xs={12} lg={6}>
                          <DownloadGraphOverlay>
                            <Row>
                              <Col style={{ minWidth: '350px' }} xs={'auto'}>
                                <CustomSelect
                                  className="basic-single mt-2"
                                  type={'dark'}
                                  classNamePrefix="select"
                                  isSearchable={true}
                                  placeholder={t('Select media') + '...'}
                                  name={`features-select`}
                                  isDisabled={false}
                                  isClearable={true}
                                  options={features.map((f) => ({
                                    label: f,
                                    value: f,
                                  }))}
                                  value={
                                    budgetMode
                                      ? selectedFeatureBudget
                                      : selectedFeatureOutcome
                                  }
                                  onChange={(v) =>
                                    budgetMode
                                      ? setSelectedFeatureBudget(v)
                                      : setSelectedFeatureOutcome(v)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                style={{ minHeight: '450px' }}
                                className="relative"
                                xs={12}
                                lg={11}
                              >
                                {currentFeatureSelected?.value && (
                                  <BestSpend
                                    model={model}
                                    key={currentFeatureSelected.value}
                                    channel={currentFeatureSelected.value}
                                    targetSpend={
                                      (budgetMode
                                        ? optimizeTableBars
                                        : optimizeTableOutcomeBars
                                      )?.spendPerChannel?.[
                                        currentFeatureSelected.value
                                      ]
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                          </DownloadGraphOverlay>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
            <Col
              className={`train-container mt-4 py-3 rounded-2xl relative duration-200`}
              xs={12}
            >
              <Row className="ps-3">
                <Col xs={12} className={`text-xl mb-3 duration-200`}>
                  {t('Time allocation')}
                </Col>
                <Col xs={12}>
                  <DownloadGraphOverlay
                    excelFirst={true}
                    buttonsStyle={{ top: '3px', right: '5px' }}
                    additionItems={
                      <ButtonGroup className="me-1">
                        <Button
                          className={`download-graph-button me-0 `}
                          onClick={() => setStackedGraphMode(false)}
                          style={{
                            backgroundColor: !stackedGraphMode
                              ? 'var(--mmm-button-outline)'
                              : 'transparent',
                            border: 'none',
                          }}
                        >
                          <MdOutlineStackedBarChart />
                        </Button>
                        <Button
                          className={`download-graph-button me-0 `}
                          onClick={() => setStackedGraphMode(true)}
                          style={{
                            backgroundColor: stackedGraphMode
                              ? 'var(--mmm-button-outline)'
                              : 'transparent',
                            border: 'none',
                          }}
                        >
                          <FaChartArea />
                        </Button>
                      </ButtonGroup>
                    }
                  >
                    <Row className="px-3">
                      <Col
                        className="px-0"
                        style={{ maxHeight: '800px', minHeight: '800px' }}
                        xs={12}
                      >
                        {budgetMode ? budgetGraph : outcomeGraph}
                      </Col>
                    </Row>
                  </DownloadGraphOverlay>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <></>
      )}
    </Row>
  )
}
