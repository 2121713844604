import { useState, useEffect, useMemo } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  Overlay,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap'
import {
  FaChartLine,
  FaChartArea,
  FaChartPie,
  FaChevronDown,
  FaChartBar,
  FaTh,
  FaQuestionCircle,
} from 'react-icons/fa'
import { RiGlobalLine } from 'react-icons/ri'
import GlassChart from '../assets/logos/magnifying-glass-chart-solid.svg'
import ModelContentInfobox from './ModelContentInfobox'
import CustomSelect, { Option } from './CustomSelect'
import HelpTooltip from './HelpTooltip'
import { trainModel } from '../services/model'
import { useQueryClient, useQuery } from 'react-query'
import TrainProgress from './TrainProgress'
import OriginalSpend from './mmm-graphs/OriginalSpend'
import DownloadGraphOverlay from './mmm-graphs/DownloadGraphOverlay'
import MediaContributionBars from './mmm-graphs/MediaContributionBars'
import PredictionChart from './mmm-graphs/PredictionChart'
import InsightsGraphCard from './InsightsGraphCard'
import MediaContribution from './mmm-graphs/MediaContribution'
import FunnelEffect from './mmm-graphs/FunnelEffect'
import TotalFunnelEffect from './mmm-graphs/TotalFunnelEffect'
import LagAndCarryover from './mmm-graphs/LagAndCarryover'
import DynamicSpend from './mmm-graphs/DynamicSpend'
import Influence from './mmm-graphs/Influence'
import BestSpend from './mmm-graphs/BestSpend'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/AuthProvider'
import { defaultFormat, readableNumberMMM } from '../utility/format'
import ExportReport from './ExportReport'
import SpendContribution from './mmm-graphs/SpendContribution'
import { getModelStatus } from '../utility/model'

const TRAIN_OPTIONS = [
  { label: 'Fast (≈ 0-3 min)', value: 1 },
  { label: 'Performance (≈ 3-5 min)', value: 2 },
  { label: 'Accurate (≈ 6-10 min)', value: 5 },
]

const VALID_KPI = new Set(['float64', 'Integer'])
const VALID_DATECOl = new Set(['Datetime', 'Integer'])

export default function ModelInsights({ model, pushTitle, popTitle }) {
  const { t } = useTranslation()
  const { token, isEssential } = useAuth()
  const statsURL =
    'https://support.dataslayer.ai/understanding-advanced-statistics-in-marketing-mix-modeling-mmm'
  useEffect(() => {
    pushTitle(`Dataslayer MMM | Insights ${model?.name}`)
    return () => popTitle()
  }, [])

  const popover = (
    <Popover id="popover-statistics">
      <Popover.Header as="h3">
        <div className="flex justify-between items-center">
          <div>
            <span>{t('Advanced statistics')}</span>
          </div>
          <a className="link-stats" href={statsURL} target="_blank">
            <span className="inline-flex items-center align-middle text-base">
              <FaQuestionCircle className="me-2" />
            </span>
          </a>
        </div>
      </Popover.Header>
      <Popover.Body>
        <table className="table-popover">
          <tbody>
            {model?.score ? (
              Object.keys(model?.score).map((metric) => (
                <tr key={metric}>
                  <td>{metric}</td>
                  <td>{defaultFormat({ num: model.score[metric] })}</td>
                </tr>
              ))
            ) : (
              <p>{t('No advanced statistics available')}</p>
            )}
          </tbody>
        </table>
      </Popover.Body>
    </Popover>
  )
  const queryClient = useQueryClient()
  const dataslayerConfig = model.dataslayer_training_config

  const [kpi, setKpi] = useState(() => {
    if (dataslayerConfig) {
      return {
        label: model.dataslayer_training_config.kpi,
        value: model.dataslayer_training_config.kpi,
      }
    }
    if (model?.training_config?.target)
      return {
        label: model?.training_config?.target,
        value: model?.training_config?.target,
      }
  })
  const [dateCol, setDateCol] = useState(() => {
    if (dataslayerConfig) {
      return {
        label: model.dataslayer_training_config.time_column,
        value: model.dataslayer_training_config.time_column,
      }
    }
    if (model?.training_config?.datetime_col)
      return {
        label: model.training_config.datetime_col,
        value: model.training_config.datetime_col,
      }
  })
  const [extraFeatures, setExtraFeatures] = useState(() => {
    if (dataslayerConfig) {
      return (
        model.dataslayer_training_config?.context_variables?.map((v) => ({
          label: v,
          value: v,
        })) ?? []
      )
    }
    return (
      model?.training_config?.extra_features_cols?.map((v) => ({
        label: v,
        value: v,
      })) ?? []
    )
  })
  const [columnsToIgnore, setColumnsToIgnore] = useState(() => {
    return (
      model?.training_config?.columns_to_ignore?.map((v) => ({
        label: v,
        value: v,
      })) ?? []
    )
  })
  const [trainingQuality, setTrainingQuality] = useState(() => {
    return TRAIN_OPTIONS.find(
      (v) => v.value === model?.training_config?.minutes,
    )
  })

  const [trainingRequest, setTrainingRequest] = useState(null)
  const [collapsed, setCollapsed] = useState(
    getModelStatus(model) === 'training',
  )
  const ignoredSet = new Set(columnsToIgnore.map((c) => c.value))
  let mediaChannelsCount = dataslayerConfig
    ? dataslayerConfig?.media_channels?.filter((m) => !ignoredSet.has(m))
        ?.length ?? 0
    : (model?.trainable_columns?.length ?? 0) -
      2 -
      columnsToIgnore?.length -
      extraFeatures?.length

  useEffect(() => {
    if (trainingRequest) {
      trainModel({
        modelId: model.id,
        trainConfig: trainingRequest,
        token,
      })
        .catch((e) => {
          //TODO notify user of error
        })
        .finally(() => {
          setCollapsed(true)
          setTrainingRequest(null)
          queryClient.invalidateQueries(['model', model.id])
          queryClient.invalidateQueries(['models', token])
        })
    }
  }, [trainingRequest])

  const kpiOptions = useMemo(
    () =>
      model.trainable_columns
        ?.filter(
          (v) =>
            v !== dateCol?.value && VALID_KPI.has(model?.column_types?.[v]),
        )
        ?.map((c) => ({ label: c, value: c })),
    [model],
  )

  const dateColOptions = useMemo(
    () =>
      model.trainable_columns
        ?.filter(
          (v) =>
            v !== kpi?.value && VALID_DATECOl.has(model?.column_types?.[v]),
        )
        ?.map((c) => ({ label: c, value: c })),
    [model],
  )

  const extraFeaturesOptions = useMemo(() => {
    const used = new Set()
    if (kpi) used.add(kpi?.value)
    if (dateCol) used.add(dateCol?.value)
    columnsToIgnore?.forEach((c) => used.add(c.value))
    return (
      model.trainable_columns
        ?.filter((v) => !used.has(v))
        ?.map((c) => ({ label: c, value: c })) ?? []
    )
  }, [model, kpi, columnsToIgnore])

  const ignoreColumnsOptions = useMemo(() => {
    if (dataslayerConfig) {
      return (
        model?.trainable_columns
          ?.filter(
            (c) =>
              c !== model.dataslayer_training_config.kpi &&
              c !== model.dataslayer_training_config.time_column,
          )
          ?.map((c) => ({ label: c, value: c })) ?? []
      )
    }

    const used = new Set()
    if (kpi) used.add(kpi?.value)
    if (dateCol) used.add(dateCol?.value)
    extraFeatures?.forEach((c) => used.add(c.value))
    return (
      model.trainable_columns
        ?.filter((v) => !used.has(v))
        ?.map((c) => ({ label: c, value: c })) ?? []
    )
  }, [model, kpi, extraFeatures])

  const [activeCard, setActiveCard] = useState(isEssential ? 2 : 1)
  const RESULTS_GRAPHS = [
    {
      title: t('Response model accuracy'),
      helpText: t('Response model accuracy tooltip'),
      icon: <FaChartLine />,
      class: '',
      component: (
        <PredictionChart
          model={model}
          isInView={true}
          target={model?.training_config?.target}
          style={{ minHeight: '450px' }}
        />
      ),
      requirePro: model?.read_only ? false : true,
    },
    {
      title: t('Current media mix'),
      helpText: t('Current media mix tooltip'),
      icon: <FaChartPie />,
      class: '',
      component: <OriginalSpend model={model} />,
    },
    {
      title: t('Global effect'),
      helpText: t(
        'Percentage of Effect attributed to each variable in the model.',
      ),
      icon: <RiGlobalLine />,
      class: '',
      component: <SpendContribution model={model} key={1} />,
    },
    {
      title: t('Media effect vs Spend'),
      helpText: t(
        'Percentage of Effect attributed to each media channel in the model VS the real Spend.',
      ),
      icon: <FaChartBar />,
      class: '',
      requirePro: model?.read_only ? false : true,
      component: (
        <SpendContribution
          model={model}
          showExtraFeatures={false}
          showRecommend={true}
          showSpend={true}
          requirePro={model?.read_only ? false : true}
          key={2}
        />
      ),
    },
    {
      title: t('Effect over time per week'),
      helpText: t('Media effects - weekly tooltip'),
      icon: <FaChartArea />,
      class: '',
      component: (
        <MediaContribution
          model={model}
          isInView={true}
          target={model?.training_config?.target}
          height={450}
        />
      ),
    },
    {
      title: t('Cross-channel impact'),
      helpText: t('Cross-channel impact tooltip'),
      icon: <FaTh />,
      class: '',
      component: (
        <FunnelEffect
          isInView={true}
          model={model}
          style={{ minHeight: '450px', maxHeight: '450px' }}
        />
      ),
      requirePro: model?.read_only ? false : true,
    },
    {
      title: t('KPI Summary breakdown'),
      helpText: t('KPI Summary Breakdown tooltip'),
      icon: <FaTh />,
      class: '',
      component: <TotalFunnelEffect model={model} isInView={true} />,
      requirePro: model?.read_only ? false : true,
    },
    {
      title: t('Lag and carryover'),
      helpText: t('Lag and carryover tooltip'),
      icon: <FaChartLine />,
      class: 'lag-carryover-container',
      component: (
        <LagAndCarryover model={model} isInView={true} height={'450px'} />
      ),
    },
    {
      title: t('Ad to consumer response'),
      helpText: t('Ad to consumer response tooltip'),
      icon: <FaChartArea />,
      class: 'dynamic-spend-container',
      component: <DynamicSpend model={model} isInView={true} />,
      requirePro: model?.read_only ? false : true,
    },
    {
      title: t('Non media contribution'),
      helpText: t('Non media contribution tooltip'),
      icon: <FaChartArea />,
      class: 'influence-container',
      component: <Influence model={model} isInView={true} />,
    },
    {
      title: t('Shape effect'),
      helpText: t('Shape effect (diminishing returns) tooltip'),
      icon: <FaChartLine />,
      class: 'best-spend-container',
      component: (
        <BestSpend
          model={model}
          isInView={true}
          target={model?.training_config?.target}
        />
      ),
      requirePro: model?.read_only ? false : true,
    },
  ]
  RESULTS_GRAPHS.forEach((c, i) => (c.id = i + 1))

  const activeGraph = RESULTS_GRAPHS.find((card) => card.id === activeCard)
  const badColumns = (model?.trainable_columns ?? []).length < 3

  let accuracy = model?.accuracy
  if (accuracy < 0) accuracy = 0

  return (
    <Row>
      <Col className={`${isEssential ? 'hidden' : ''}`} xs={12}>
        <ModelContentInfobox
          applyInfoboxClass={true}
          name={'insights_infobox'}
          collapsable={true}
          className="mb-2"
        >
          {badColumns ? (
            <span className="text-red-400 font-bold">
              {t('Insights infobox bad columns')}
            </span>
          ) : (
            t('Insights infobox')
          )}
        </ModelContentInfobox>
      </Col>
      {!model?.read_only && (
        <Col
          className={`train-container  pt-3 pb-5 rounded-2xl relative duration-200 ${collapsed ? 'max-h-0 overflow-hidden' : 'max-h-96'} ${isEssential ? 'hidden' : ''}`}
          xs={12}
        >
          <FaChevronDown
            size={20}
            className={`cursor-pointer right-7 absolute z-10 duration-200 ${collapsed ? '-rotate-90' : ''}`}
            onClick={() => setCollapsed(!collapsed)}
          />
          <Row className="ps-3">
            <Col xs={12} className={`text-xl mb-3  duration-200 font-medium`}>
              {t('Training configuration')}
            </Col>
            <Col xs={10}>
              <Row className="px-2">
                <Col
                  className={`mb-3 ${dataslayerConfig ? 'hidden' : ''}`}
                  xxl={3}
                  md={4}
                  xs={6}
                >
                  <Row>
                    <Col
                      className="font-thin mb-1 inline-flex items-center"
                      xs={12}
                    >
                      {t('KPI Column')} *
                      <HelpTooltip
                        message={t('KPI column tooltip')}
                        className="ms-2 mt-1"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomSelect
                        className="basic-single mt-2"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder={'Select...'}
                        isClearable={true}
                        onChange={(e) => {
                          setKpi(e)
                          setExtraFeatures([])
                          setColumnsToIgnore([])
                        }}
                        options={kpiOptions}
                        value={kpi}
                        isDisabled={getModelStatus(model) === 'training'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  className={`mb-3 ${dataslayerConfig ? 'hidden' : ''}`}
                  xxl={3}
                  md={4}
                  xs={6}
                >
                  <Row>
                    <Col
                      className="font-thin mb-1 inline-flex items-center"
                      xs={12}
                    >
                      {t('Date Column')}
                      <HelpTooltip
                        message={t('Date column tooltip')}
                        className="ms-2 mt-1"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomSelect
                        className="basic-single mt-2"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder={'Select...'}
                        isClearable={true}
                        onChange={(e) => {
                          setDateCol(e)
                          setExtraFeatures([])
                          setColumnsToIgnore([])
                        }}
                        options={dateColOptions}
                        value={dateCol}
                        isDisabled={getModelStatus(model) === 'training'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  className={`mb-3 ${dataslayerConfig ? 'hidden' : ''}`}
                  xxl={3}
                  md={4}
                  xs={6}
                >
                  <Row>
                    <Col
                      className="font-thin mb-1 inline-flex items-center"
                      xs={12}
                    >
                      {t('Extra features')}
                      <HelpTooltip
                        message={t('Extra features column tooltip')}
                        className="ms-2 mt-1"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomSelect
                        className="basic-single mt-2"
                        value={extraFeatures}
                        onChange={(e) => setExtraFeatures(e)}
                        options={extraFeaturesOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        isDisabled={
                          !kpi ||
                          !dateCol ||
                          getModelStatus(model) === 'training'
                        }
                        components={{
                          Option,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="mb-3" xxl={3} md={4} xs={6}>
                  <Row>
                    <Col
                      className="font-thin mb-1 inline-flex items-center"
                      xs={12}
                    >
                      {t('Columns to ignore')}
                      <HelpTooltip
                        message={t('Columns to ignore tooltip')}
                        className="ms-2 mt-1"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomSelect
                        className="basic-single mt-2"
                        value={columnsToIgnore}
                        onChange={(e) => setColumnsToIgnore(e)}
                        options={ignoreColumnsOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        isDisabled={
                          !kpi ||
                          !dateCol ||
                          getModelStatus(model) === 'training'
                        }
                        components={{
                          Option,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="mb-3" xxl={3} md={4} xs={6}>
                  <Row>
                    <Col
                      className="font-thin mb-1 inline-flex items-center"
                      xs={12}
                    >
                      {t('Training quality')}
                      <HelpTooltip
                        message={t('Training quality tooltip')}
                        className="ms-2 mt-1"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomSelect
                        value={trainingQuality}
                        className="basic-single mt-2"
                        classNamePrefix="select"
                        isSearchable={true}
                        isDisabled={
                          !kpi ||
                          !dateCol ||
                          getModelStatus(model) === 'training'
                        }
                        placeholder={'Select...'}
                        isClearable={true}
                        onChange={(e) => setTrainingQuality(e)}
                        options={TRAIN_OPTIONS}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={2}>
              <Row className="me-2">
                <Col className="font-thin opacity-0 select-none mb-1" xs={12}>
                  placeholder
                </Col>
                <Col>
                  <button
                    className="w-full button-mmm rounded-3xl py-2 bg-main disabled:bg-slate-950 disabled:pointer-events-none mt-2 font-bold"
                    disabled={
                      !kpi ||
                      !dateCol ||
                      trainingRequest ||
                      mediaChannelsCount > 100 ||
                      getModelStatus(model) === 'training'
                    }
                    onClick={() => {
                      setTrainingRequest({
                        target: kpi.value,
                        datetime_col: dateCol.value,
                        extra_features_cols: extraFeatures.map((f) => f.value),
                        columns_to_ignore: columnsToIgnore.map((c) => c.value),
                        minutes: trainingQuality?.value ?? 1,
                      })
                    }}
                  >
                    {t('Train model')}
                  </button>
                </Col>
                {kpi?.value && dateCol?.value && (
                  <Col xs={12} className="text-center text-xs mt-2">
                    {mediaChannelsCount <= 100
                      ? t(`{{count}} media channels selected`, {
                          count: mediaChannelsCount,
                        })
                      : t(
                          `Maximum media channels exceeded, models with more than 100 media channels are not supported. Please ignore some columns.`,
                        )}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      {getModelStatus(model) === 'training' && (
        <Col xs={12}>
          <TrainProgress model={model} />
        </Col>
      )}
      {getModelStatus(model) === 'trained' && (
        <Col
          className={`train-container mt-4 py-3 rounded-2xl relative duration-200 ${isEssential ? '!bg-transparent' : ''}`}
          xs={12}
        >
          <Row className="px-3">
            <Col xs={12}>
              <Row className="justify-between">
                <Col
                  xs="auto"
                  className={`text-xl mb-3 font-medium ${isEssential ? 'hidden' : ''}`}
                >
                  {t('Training results')}
                </Col>
                <Col className={`${isEssential ? 'hidden' : ''}`} xs="auto">
                  <ExportReport />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row className="px-0">
                <Col className="flex px-1" xl={2} lg={3} md={4} xs={6}>
                  <Card className="results-card bg-transparent border-2 border-dashed border-white  text-white p-0 w-full my-2 !rounded-e-[0px] non-border-r">
                    <Card.Body className="px-1 py-2 flex justify-center items-center flex-col">
                      <Card.Title className="result-title !text-[16px]  inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full">
                        {accuracy?.toFixed(2) ?? 0}%
                      </Card.Title>
                      <Card.Subtitle className="result-label mb-2 opacity-60 font-medium !text-[14px] inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full">
                        {t('Accuracy')}
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                  <Card className="results-card bg-transparent border-2 border-dashed border-white  text-white p-0 w-full my-2 !rounded-s-[0px] !border-l-[1px]">
                    <Card.Body className="px-1 py-2 flex justify-center items-center flex-col">
                      <Card.Title className="result-title !text-[14px]  inline-block whitespace-nowrap overflow-ellipsis text-truncate max-w-full">
                        {readableNumberMMM(
                          100 - (accuracy?.toFixed(2) ?? 0),
                          2,
                          4,
                        )}
                        %
                      </Card.Title>
                      <Card.Subtitle className="result-label mb-2 opacity-60 font-medium !text-[14px] text-truncate max-w-full">
                        {t('Margin error')}
                      </Card.Subtitle>
                      <OverlayTrigger
                        rootClose={true}
                        trigger={['click']}
                        placement="auto"
                        delay={{ show: 100, hide: 100 }}
                        overlay={popover}
                      >
                        <div
                          className="tooltip-custom cursor-pointer"
                          style={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '5px',
                          }}
                        >
                          <img
                            className="icon-item h-5 max-w-none"
                            src={GlassChart}
                          />
                          <span className="tooltip-text">
                            {t('Advanced statistics')}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </Card.Body>
                  </Card>
                </Col>
                {RESULTS_GRAPHS.map((card, i) => (
                  <Col
                    key={i}
                    xl={2}
                    lg={3}
                    md={4}
                    xs={6}
                    className="my-2 px-1"
                  >
                    <InsightsGraphCard
                      key={card.id}
                      name={card.title}
                      icon={card.icon}
                      isActive={activeCard === card.id}
                      onClick={() => setActiveCard(card.id)}
                      helpText={card.helpText}
                      requirePro={card.requirePro}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <Row className="my-2 px-2">
                <Card body className="insight-graph-card">
                  {activeCard !== null && (
                    <>
                      <DownloadGraphOverlay
                        title={activeGraph.title}
                        buttonsStyle={{ top: '0px', zIndex: 10 }}
                        disabled={activeGraph.requirePro && isEssential}
                        customFilename={activeGraph.title}
                      >
                        <Col
                          className={`mt-2 relative ${activeGraph.class}`}
                          xs={12}
                        >
                          {activeGraph.component}
                        </Col>
                      </DownloadGraphOverlay>
                    </>
                  )}
                </Card>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}
